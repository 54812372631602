import React from "react";
import Layout from "../components/layout";
import BookHomePageCard from "../components/material-ui/BookHomePageCard";
import { graphql, Link } from "gatsby";
import { Grid } from "@mui/material";
import { getImage } from "gatsby-plugin-image";

const Home = ({ data }) => {
  return (
    <Layout>
      <Grid container  lg={12} md={12} sm={12} xs={12}>
        {data.allWpBook.edges.map((edge) => {
          const image = getImage(edge.node.featuredImage.node.localFile);
          return (
            <Grid item margin="auto" marginY={2} lg={3} md={5} sm={5} xs={10}>
              <BookHomePageCard
                book_title={edge.node.acfbook.bookTitle}
                book_image={image}
              />
            </Grid>
          );
        })}
      </Grid>
      <Link to="/num1">Sample Numerical</Link>
    </Layout>
  );
};

export default Home;

export const HomePagequery = graphql`
  {
    allWpBook {
      edges {
        node {
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 345, height: 194, placeholder : TRACED_SVG)
                }
              }
            }
          }
          acfbook {
            isbn10
            isbn13
            bookTitle
          }
          title
        }
      }
    }
  }
`;
